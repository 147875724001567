<template>

  <div class="cover-bg" :style="'background-image: url(\'' + widget.styles.bgimage + '\');'">
    <div class="inner-content">
      <h2 class="text-white">{{ widget.styles.headline }}</h2>
      <button @click="startVideo" class="btn btn_orange mt-3">{{ $store.getters.texts("interview.play") }}</button>
    </div>
  </div>

  <div id="video_container" v-show="showVideo">
    <div class="inner-video">
      <div @click="showVideo = false" class="relative mb-2 h-5 w-5 md:h-7 md:w-7 transition-transform duration-200 cross transform rotate-45 cursor-pointer">
        <div class="absolute rounded bg-white horizontal"></div>
        <div class="absolute rounded bg-white vertical"></div>
      </div>
      <video
          :alt="widget.styles.headline"
          class="video_full"
          controls
          ref="video"
          :src="widget.mp4"
      />
    </div>
    <div class="overlay cursor-pointer" @click="showVideo = false"></div>
  </div>

</template>

<script>
export default {
  props: { widget: Object },
  name: "VideoWidget",
  data() { return { showVideo: false }; },
  methods: {
    startVideo() {
      this.showVideo = true;
      this.$refs.video.play();
    }
  },
  watch: {
    showVideo(value) { if (!value) this.$refs.video.pause(); }
  }
};
</script>