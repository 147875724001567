<template>
  <div class="sf_six text-white text-center">
    <div class="fluid_content_container">
      <section class="sf_six_section relative">
        <img class="mb-10" src="../../assets/img/01_sf6.svg">
        <h1 class="mb-6" v-html="$store.getters.texts('sfSix.openerHeading')"></h1>
        <p class="teaser" v-html="$store.getters.texts('sfSix.openerText')"></p>
        <div class="scroll-down cursor-pointer" @click="scroll(1)">
          <span></span>
          <span></span>
        </div>
      </section>
      <section class="sf_six_section">
        <img class="mb-10" src="../../assets/img/02_durchschlag.svg">
        <p class="teaser" v-html="$store.getters.texts('sfSix.punchThrough')"></p>
      </section>
      <section class="sf_six_section">
        <img src="../../assets/img/03_treibhaus.svg">
        <p class="teaser" v-html="$store.getters.texts('sfSix.greenHouse')"></p>
      </section>
      <section class="sf_six_section">
        <img src="../../assets/img/04_atmosphere.svg">
        <p class="teaser" v-html="$store.getters.texts('sfSix.atmosphere')"></p>
      </section>
      <section class="sf_six_section">
        <img class="mb-5" src="../../assets/img/05_medizin.svg">
        <p class="teaser" v-html="$store.getters.texts('sfSix.medicine')"></p>
      </section>
      <section class="sf_six_section">
        <img src="../../assets/img/06_wolken.svg">
        <p class="teaser" v-html="$store.getters.texts('sfSix.clouds')"></p>
      </section>
      <section class="sf_six_section">
        <img src="../../assets/img/07_jahr2000.svg">
        <p class="teaser" v-html="$store.getters.texts('sfSix.year2k')"></p>
      </section>
      <section class="sf_six_section">
        <h1 class="mb-6" v-html="$store.getters.texts('sfSix.futureHeading')"></h1>
        <p class="teaser" v-html="$store.getters.texts('sfSix.futureText')"></p>
      </section>
    </div>
    <div class="fluid_content_container wide">
      <section class="sf_six_section">
        <img v-if="$store.state.language === 'en'" src="../../assets/img/abbinder_en.svg">
        <img v-else src="../../assets/img/abbinder_de.svg">
      </section>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

export default {
  name: "SF6",
  methods: {
    initGSAP() {
      gsap.set(".scroll-down span", { opacity: 0 });
      setTimeout(function() {
        const scrollDown = gsap.timeline({ repeat: -1, repeatDelay: 1 });
        scrollDown.fromTo(".scroll-down span", { y: -50, opacity: 0 }, { y: 0, opacity: .8, duration: 1, stagger: .5, ease: "power4.out" });
        scrollDown.to(".scroll-down span", { y: 50, opacity: 0, duration: 1, stagger: .5, ease: "power4.in" }, "-=0");
      }, 1000);
    },
    scroll(value) {
      document.getElementsByClassName("sf_six_section")[value].scrollIntoView({ behavior: "smooth", block: "start" });
    }
  },
  mounted() {
    this.$nextTick(function() { this.initGSAP(); });
  }
};
</script>