<template>
  <div :style="style" class="postcard page_content absolute overflow-hidden text-white pt-8 min-h-screen w-full flex">
    <div class="flip-container m-auto" ontouchstart="this.classList.toggle('hover');">
      <div class="flipper relative">
        <img :src="widget.styles.frontimg" class="opacity-0">
        <img
            :src="widget.styles.frontimg"
            class="front z-10"
        >
        <img
            :src="widget.styles.backimg"
            class="back"
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ScratchCard",
  computed: {
    style() {
      let result = "";
      if (this.widget.cover) result = "background-image: url('" + this.widget.cover + "');";
      return result;
    },
    widget() { return this.$store.getters.currentWidget; },
  }
};
</script>
