<template>
  <div class="welcome text-white fluid_content_container text-center">
    <section id="s1" class="page-section my-0 h-100 d-flex-center">
      <p class="main-animate text-dilo-blue">{{ $store.getters.texts("welcome.overline") }}</p>

      <h1 class="heading text-4xl lg:text-5xl px-3 sm:px-0 uppercase mb-3">
        <div class="main-animate wir-feiern font-bold">{{ $store.getters.texts("welcome.headline1") }}</div>
        <div class="main-animate nur-eben-anders font-thin -mt-1">{{ $store.getters.texts("welcome.headline2") }}</div>
      </h1>
      <div class="w-7/12 mx-auto mb-2 relative main-animate">
        <div id="myearth"></div>
        <img src="../../assets/img/70_years_welcome.svg" alt="70 years">
      </div>
      <div class="scroll-down">
        <span></span>
        <span></span>
      </div>
    </section>

    <section id="s2" class="page-section mt-0 d-flex-top">
      <div class="teaser mx-auto pt-16 mb-8">
        <p class="text-primary section-heading">{{ $store.getters.texts("welcome.celebrate1") }}</p>
        <p class="section-heading">{{ $store.getters.texts("welcome.celebrate2") }}</p>

        <div class="marker mt-16  s2-inner-content">
          <div class="s2-inner-content-body">
            <div class="celebrate-text" id="celebrateSwitch1">{{ $store.getters.texts("welcome.celebrateSwitch1") }}</div>
            <div class="celebrate-text" id="celebrateSwitch2">{{ $store.getters.texts("welcome.celebrateSwitch2") }}</div>
            <div class="celebrate-text" id="celebrateSwitch3">{{ $store.getters.texts("welcome.celebrateSwitch3") }}</div>
            <div class="celebrate-text" id="celebrateSwitch4">{{ $store.getters.texts("welcome.celebrateSwitch4") }}</div>
            <div class="celebrate-text" id="celebrateSwitch5">{{ $store.getters.texts("welcome.celebrateSwitch5") }}</div>
            <div class="celebrate-text" id="celebrateSwitch6">{{ $store.getters.texts("welcome.celebrateSwitch6") }}</div>
          </div>
        </div>
      </div>
    </section>

    <section id="s3" class="page-section d-flex-center">
      <div class="teaser mx-auto">
        <div class="s3-inner-content">
          <div class="s3-inner-content-heading">
            <p class="section-heading"> {{ $store.getters.texts("welcome.noParty1") }}</p>
            <p class="section-heading"> {{ $store.getters.texts("welcome.noParty2") }}</p>
            <p class="section-heading text-bg-white text-uppercase"> {{ $store.getters.texts("welcome.noParty3") }}</p>
          </div>
          <div class="s3-inner-content-body">
           <p class="s3-anim-text"> {{ $store.getters.texts("welcome.noParty4") }} <span class="divider"></span></p>
           <p class="s3-anim-text"> {{ $store.getters.texts("welcome.noParty5") }} <span class="divider"></span></p>
           <p class="s3-anim-text"> {{ $store.getters.texts("welcome.noParty6") }} <span class="divider"></span></p>
           <p class="" id="correct-service"> {{ $store.getters.texts("welcome.noParty7") }}</p>
          </div>
        </div>
      </div>
    </section>

    <section id="s4" class="page-section d-flex-center">
      <!-- <hr class="my-12 border-dilo-gray"> -->

      <p class="section-heading teaser mx-auto">{{ $store.getters.texts("welcome.flags.heading") }}</p>

      <div class="fahnen">
        <div class="fahne fahne1 mb-10 w-1/3">
          {{ $store.getters.texts("welcome.flags.interviews") }}
          <div class="after"></div>
        </div>
        <div class="fahne fahne2 mb-10 w-1/3">
          {{ $store.getters.texts("welcome.flags.articles") }}
          <div class="after"></div>
        </div>
        <div class="fahne fahne3 mb-10 w-5/12">
          {{ $store.getters.texts("welcome.flags.quiz") }}
          <div class="after"></div>
        </div>
        <div class="fahne fahne4 mb-10 w-7/12">
          {{ $store.getters.texts("welcome.flags.witness") }}
          <div class="after"></div>
        </div>
      </div>

      <p class="teaser mx-auto mb-4">
        <span class="text-dilo-gray">
          {{ $store.getters.texts("welcome.flags.outro1") }}<br>
          {{ $store.getters.texts("welcome.flags.outro2") }}
        </span><br>
        {{ $store.getters.texts("welcome.flags.outro3") }}<br>
        {{ $store.getters.texts("welcome.flags.outro4") }}
      </p>

      <hr class="my-12 border-dilo-gray">
    </section>

    <section id="s5" class="page-section d-flex-center">

      <div class="mx-auto s5-top-title">
        <p class="section-subheading">{{ $store.getters.texts("welcome.invitation1") }} </p>
        <!-- <p class="section-subheading"></p> -->
      </div>
      <div class="section-intro-text">
        <p class="section-heading">{{ $store.getters.texts("welcome.invitation2") }}</p>
        <p class="section-heading">{{ $store.getters.texts("welcome.invitation3") }}</p>
      </div>
      <div class="s5-inner-content my-5">
          <div class="s5-inner-content-body">
            <div class="celebrate-text" id="invitationSwitch1">{{ $store.getters.texts("welcome.invitationSwitch1") }}<div class="text-revealer"></div> </div>
            <div class="celebrate-text" id="invitationSwitch2">{{ $store.getters.texts("welcome.invitationSwitch2") }}<div class="text-revealer"></div> </div>
            <div class="celebrate-text" id="invitationSwitch3">{{ $store.getters.texts("welcome.invitationSwitch3") }}<div class="text-revealer"></div> </div>
            <div class="celebrate-text" id="invitationSwitch4">{{ $store.getters.texts("welcome.invitationSwitch4") }}<div class="text-revealer"></div> </div>
            <div class="celebrate-text" id="invitationSwitch5">{{ $store.getters.texts("welcome.invitationSwitch5") }}<div class="text-revealer"></div> </div>
          </div>
        </div>

        <div class=" s5-bottom">
          {{ $store.getters.texts("welcome.outro1") }}<br>
          {{ $store.getters.texts("welcome.outro2") }}<br>
          {{ $store.getters.texts("welcome.outro3") }}
          <img v-if="$store.state.language === 'en'" src="../../assets/img/dilo_team_en.svg" class="w-7/12 mx-auto mt-5 z-10 relative" alt="70 years">
          <img v-else src="../../assets/img/dilo_team.svg" class="w-7/12 mx-auto mt-5 z-10 relative" alt="70 Jahre">
        </div>
    </section>

  </div>

  <section id="s6" class="my-0 page-section h-100 d-flex-center">
    <picture class="footer-image pt-32">
      <img
          src="../../assets/img/visual_70years_welcome.png"
          class="w-9/12 mx-auto relative welcome-visual -mt-16 md:-mt-32 lg:-mt-48 xl:-mt-56 2xl:-mt-56"
          alt="70 years"
      >
    </picture>
  </section>
</template>

<style>
  .page-section{
    height: 100%;
    margin: 25% 0;
    position: relative;
  }
  .mt-0{
    margin-top: 0 !important;
  }
  .mb-0{
    margin-bottom: 0 !important;
  }
  .my-0{
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .h-100{
    height: 100vh !important;
  }
  .footer-image{
    max-width: 68%;
    margin: 0 auto;
    margin-top: 10%;
  }
  .section-heading{
    font-size: 2rem;
    line-height: 49px;
  }
  .s5-top-title{
    position: absolute;
    top: 10%;
    text-align: center;
    left: 50%;
    transform: translateX(-50%);
    z-index: 20;
  }
  .s5-inner-content{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .s5-inner-content-body .celebrate-text{
    position: relative;
    z-index: 0;
    width: max-content;
    margin: 0 auto;
  }
  .s5-inner-content-body .celebrate-text .text-revealer{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #1a3642;
    transform-origin: "top right";
    z-index: 0;
  }
  .section-intro-text{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
  }
  .section-intro-text .section-heading{
    font-weight: 700;
    text-transform: uppercase;
    font-family: "UniversLTCYR-57Condensed";
    padding: 0 100px;
  }
  .section-subheading{
    font-size: 1.25rem;
    /* line-height: 49px; */
    font-weight: 300;
    white-space: nowrap;
    text-transform: normal;
  }
  .text-primary{
    color: #0094D8;
  }
  .d-flex-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .d-flex-top{
    display: flex;
    flex-direction: column;
    justify-content: start;
  }
  .s2-inner-content{
    position: relative;
    height: 130px;
    overflow: hidden;
  }
  .s2-inner-content::after{
    z-index: 2;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(180deg, #1A3642 0%, rgba(26, 54, 66, 0) 100%);
  }
  .s2-inner-content::before{
    z-index: 2;
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: linear-gradient(to top, #1A3642 0%, rgba(26, 54, 66, 0) 100%);
  }
  .s3-inner-content-heading{
    text-align: center;
    margin-bottom: 1rem;
  }
  .s3-inner-content-heading .text-bg-white{
    text-transform: uppercase;
    background: white;
    padding: 10px 15px 5px;
    color: #1A3642;
    width: max-content;
    margin: 0 auto;
    line-height: 50px;
    font-weight: bolder;
    letter-spacing: 0.12em;
    font-family: "UniversLTCYR-57Condensed";
  }
  .s3-inner-content-body p{
    font-family: "UniversLTCYR-57Condensed";
    font-size: 38px;
    line-height: 50px;
    letter-spacing: 0.135em;
    text-transform: uppercase;
  }
  .celebrate-text{
    font-family: "UniversLTCYR-57Condensed";
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 50px;
    padding: 5px 0;
    /* or 132% */

    text-align: center;
    letter-spacing: 0.135em;
    text-transform: uppercase;
  }
  .s3-anim-text{
    font-family: "UniversLTCYR-57Condensed";
    position: relative;
    width: max-content;
    margin: 0 auto;
    font-weight: 900;
  }
  .s3-anim-text .divider{
    position: absolute;
    height: 5px;
    width: 100%;
    background: #0094D8;
    left: 0;
    top: 40%;
    transform-origin: "left top";
  }
  .fahnen{
    height: 200px;
    position: relative;
    margin-bottom: 3rem;
  }
  .fahnen .fahne{
    position: absolute;
  }
  .fahnen .fahne:nth-child(1){
    left:-5%;
    top: 50%;
    transform: translate(0, -50%);
  }
  .fahnen .fahne:nth-child(2){
    left:50%;
    top: 0;
    transform: translate(-50%, 0);
  }
  .fahnen .fahne:nth-child(3){
    right:-5%;
    top: 50%;
    transform: translate(0%, -50%);
  }
  .fahnen .fahne:nth-child(4){
    left:50%;
    top: 100%;
    transform: translate(-50%, -100%);
  }
  @media(max-width:576px){
    .s3-inner-content-body p, .celebrate-text {
    font-size: 26px;
}
.section-intro-text .section-heading {
    padding: 0 20px;
}
  }
</style>

<script>
import { gsap } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(CSSPlugin, ScrollTrigger);


export default {
  name: "Welcome",
  methods: {
    initEarth() {
      let script = document.createElement("script");
      script.src = "https://dilo70.eu/packages/dilo/single_pages/stories/earth/miniature.earth.js";
      document.body.appendChild(script);
      window.addEventListener("earthjsload", function() {
        /* setup earth */
        let myearth = new Earth("myearth", {
          location: { lat: 10, lng: -80 },
          autoRotate: true,
          mapLandColor: "#253541",
          mapSeaColor: "#3d5778",
          light: "none"
          /* more earth options here */
        });
        // myearth.addEventListener( "ready", function() {
        // } );

      });

      // myearth.addEventListener( "ready", function() {
      // } );

    },
    initGsap() {
      gsap.set(".scroll-down span", { opacity:0})
      gsap.set(".main-animate", {y:100, opacity:0})

      let tl2 = gsap.timeline();
      tl2
          .from(".s2-inner-content-body", { yPercent: 60, ease: "linear"})
          .to(".s2-inner-content-body", { yPercent: -75, ease: "linear"})
          // .to("#s2", { yPercent: -100, ease: "strong.in" });
      ScrollTrigger.create({
        animation: tl2,
        trigger: "#s2",
        start: "center center",
        end: "+=2000",
        // pinSpacing: false,
        scrub: true,
        pin: true,
        // anticipatePin: 1,
        // markers: true
      });

      gsap.set(".s3-inner-content-body .s3-anim-text .divider", {scaleX:0, transformOrigin: "left top"})

      gsap.set("#correct-service", {scale:1.5, opacity: 0})
      let tl3 = gsap.timeline();
      tl3
      .fromTo(".s3-inner-content-body .s3-anim-text", {scale: 1.5, opacity: 0}, {scale: 1, opacity: 1, stagger:.5, ease: 'linear'})
      .fromTo(".s3-inner-content-body .s3-anim-text .divider", {scaleX: 0}, { scaleX: 1, stagger: .5, ease: 'linear'}, .5)
      .fromTo("#correct-service", {scale: 1.5, opacity: 0}, {scale: 1, opacity: 1, stagger:.5, ease: 'linear'}, "+=0")
      .to("#correct-service", { color: "#00AFFF", duration:2, ease: 'power3'}, "-=.1")

      ScrollTrigger.create({
        animation: tl3,
        trigger: "#s3",
        start: "center center",
        end: "+=2000",
        scrub: true,
        // pinSpacing: false,
        scrub: true,
        pin: true,
        // anticipatePin: 1,
        // markers: true
      });

      gsap.set(".fahnen .fahne", {scale:0, opacity:0, rotation: 45, transformOrigin: "left top"});
      let tl4 = gsap.timeline();
      tl4.to(".fahne", {scale:1, opacity: 1, rotation: 0, stagger: .2, duration: 2, ease: "elastic"});
      ScrollTrigger.create({
        animation: tl4,
        trigger: "#s4",
        start: "40% center",
        end: "+=1000",
        // toggleActions: "play pause resume reset",
        // pinSpacing: false,
        // scrub: true,
        pin: true,
        // anticipatePin: 1,
        // markers: true
      });

      let tl5 = gsap.timeline();
      tl5
          .set(".s5-inner-content-body .celebrate-text .text-revealer", {transformOrigin: "top right"})
          .to(".s5-top-title", {y: -150,})
          .from(".section-intro-text", {scale:0, opacity:0, duration:1, ease:"power4.out"})
          .to(".section-intro-text", {delay:5, scale:1.5, opacity:0, duration:2, ease:"power4.out"})
          .fromTo(".s5-inner-content-body .celebrate-text .text-revealer", {scaleX: 1, duration:2, transformOrigin: "top right"}, { scaleX: 0, duration:2, stagger: 2, ease: 'linear',transformOrigin: "top right"}, "+=.5")
          .to(".s5-inner-content-body .celebrate-text", {color: "#b6b6b6", stagger:2}, "-=7")
          .to(".s5-inner-content-body, .s5-top-title ", {y: -250, opacity: 0, duration:2, delay: 5})
          .from(".s5-bottom", {y: 100, opacity: 0, duration:10, ease:"power4.out"}, "-=1.5")
          // .fromTo(".s3-inner-content-body .s3-anim-text", {scale: 1.5, opacity: 0}, {scale: 1, opacity: 1, stagger:.5, ease: 'linear'})
      ScrollTrigger.create({
        animation: tl5,
        trigger: "#s5",
        start: "center center",
        end: "+=6000",
        // pinSpacing: false,
        scrub: true,
        pin: true,
        anticipatePin: 1,
        // markers: true
      });


    },
  },
  mounted() {
    this.$nextTick(this.initGsap);
    this.$nextTick(this.initEarth);
    setTimeout(function(){
      const mainIntro = gsap.timeline();
      mainIntro.to(".main-animate", {y:0, opacity:1, stagger: .1, duration:2, ease: "power4.out" })
    },100)
    setTimeout(function(){
      const scrollDown = gsap.timeline({repeat: -1, repeatDelay: 1});
      scrollDown.fromTo(".scroll-down span", {y:-50, opacity:0},{y:0, opacity:.8, duration: 1, stagger:.5, ease:"power4.out"})
      scrollDown.to(".scroll-down span", {y:50, opacity:0, duration: 1, stagger:.5, ease:"power4.in"}, "-=0")
    },1000)
  }
};
</script>
