<template>
  <section :class="{ 'min-h-screen': minScreen }" :style="sectionStyle" class="widget flex flex-col justify-between overflow-hidden">
    <template v-if="widget">
      <div v-if="widget.widgettype === 'Zeitung'">
        <newspaper :url="widget.cover"></newspaper>
        <div class="content w-full bg-dilo-gray-lighter">
          <div class="v_html" v-html="html"></div>
        </div>
      </div>

      <div v-else-if="widget.widgettype === 'Postkarte'">
        <postcard></postcard>
      </div>

      <div v-else-if="widget.widgettype === 'Video'">
        <VideoWidget :widget="widget"></VideoWidget>
      </div>

      <div v-else-if="widget.widgettype === 'Scratchcard'" class="pt-20 lg:pt-12">
        <scratchcard :html="html" :widget="widget"></scratchcard>
      </div>

      <div v-else-if="widget.widgettype === 'Quiz'" class="pt-20 lg:pt-12">
        <quiz :html="html"></quiz>
      </div>

      <template v-else-if="widget.widgettype === 'Special'">
        <welcome v-if="widget.category === 'Welcome'"></welcome>
        <SF6 v-if="widget.category === 'Special'"></SF6>
        <GoodBye v-if="widget.category === 'Goodbye'"></GoodBye>
      </template>

      <div v-else-if="widget.widgettype === 'Story'" class="w-full">

        <div class="story_opener fluid_content_container wide">
          <img :src="widget.cover" alt="" height="427" width="800">
        </div>
        <div class="relative fluid_content_container wide text-white text-xs">
          <div class="absolute right-0 top-1.5 pr-2 sm:pr-0">
            <i class="far fa-calendar-alt mr-1 "></i>
            {{ widget.publishingdate }}
          </div>
        </div>
        <div class="fluid_content_container relative">
          <div :style="'color: ' + widget.styles.bgcolor" class="flag overlap">
            {{ widget.category }}
            <div class="after"></div>
          </div>
        </div>

        <div class="page_content pt-12" v-html="html"></div>
      </div>

      <iframe
          v-else-if="['Spiel','Gimmick', 'Fotostapel', 'Gyro_parallax'].includes(widget.widgettype)"
          :height="y"
          :src="widget.path"
          :style="style"
          :width="x"
          class="absolute"
      ></iframe>
    </template>

    <template v-if="minScreen">
      <Footer></Footer>
    </template>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { CSSPlugin } from "gsap/CSSPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import newspaper from "@/components/Newspaper.vue";
import scratchcard from "@/components/ScratchCard.vue";
import quiz from "@/components/Quiz.vue";
import postcard from "@/components/Postcard.vue";
import welcome from "@/pages/specials/01_welcome.vue";
import Footer from "@/components/Footer.vue";
import VideoWidget from "@/components/VideoWidget.vue";
import SF6 from "@/pages/specials/18_SF6.vue";
import GoodBye from "@/pages/specials/70_goodbye.vue";

gsap.registerPlugin(CSSPlugin, ScrollTrigger);

export default {
  name: "Widget",
  components: { SF6, VideoWidget, Footer, newspaper, scratchcard, postcard, welcome, quiz, GoodBye },
  data() {
    return {
      newspaper: false,
      html: "",
      lastID: -1,
      minScreen: true,
      x: window.innerWidth - 0.5,
      y: window.innerHeight - 0.5,
    };
  },
  methods: {
    animateStoryContent() {
      gsap.fromTo(".page_content", {
        y: 300,
      }, {
        y: 0,
        duration: .5,
        ease: "strong.out",
      });
    },
    animateStoryHeader() {
      setTimeout(function() {
        gsap.fromTo(".flag", {
          alpha: 50,
          scale: 2.4,
        }, {
          scale: 1,
          alpha: 100,
          duration: 2,
          ease: "elastic.out",
        });
      }, 50);
    },
  },
  computed: {
    sectionStyle() {
      return this.widget?.styles.backgroundImage ? `background-size:cover;background-image:url(${this.widget.styles.backgroundImage})` : "";
    },
    style() { return "touch-action: none;background-color: " + this.$store.state.bgColor + ";"; },
    widget() {
      let result = this.$store.getters.currentWidget;
      if (result && (this.$store.state.currentWidgetHandle !== this.lastID)) {
        this.lastID = this.$store.state.currentWidgetHandle;
        if (result.method === "ajax") {
          let that = this;
          that.animateStoryHeader();
          // console.time("fetch widget");
          fetch(result.path)
              .then(function(response) {
                if (response.ok) return response.text();
                else return "kein Antwort";
              })
              .then(function(data) {
                try {
                  let json = JSON.parse(data);
                  that.newspaper = json[0].newspaper;
                  that.html = json[0].content;
                  that.animateStoryContent();
                } catch (e) {
                  that.html = data;
                  that.animateStoryContent();
                }
                // console.timeEnd("fetch widget");
                that.$store.commit("loadingComplete");
              })
              .catch(function(e) {
                console.error(e);
              });
        } else if (result.method === "iframe") {
          document.body.style.overscrollBehavior = "contain";
          let that = this;
          window.addEventListener("resize", function() {
            that.x = window.innerWidth - 0.5;
            that.y = window.innerHeight - 0.5;
          });
          window.addEventListener("orientationchange", function() {
            that.x = window.innerWidth - 0.5;
            that.y = window.innerHeight - 0.5;
          });
          this.minScreen = false;
          document.body.style.overflowY = "auto";
        } else {
          this.$store.commit("loadingComplete");
        }
        this.$store.commit("changeBgColor", result.styles.bgcolor);
      }
      return result;
    }
  },
  beforeUnmount() {
    document.body.style.overscrollBehavior = "unset";
  }
};
</script>

