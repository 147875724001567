<template>
  <div class="newspaper overflow-hidden w-full flex flex-wrap content-center" style="background-color: rgb(73, 81, 86);">
    <img :src="url" class="mx-auto w-full md:w-4/5" alt="Zeitung">
  </div>
</template>

<script>
  import {gsap} from "gsap";

  export default {
    name: "Newspaper",
    props: { url: String },
    mounted() {
      gsap.fromTo('div.newspaper img', {
        scale: 0
      }, {
        scale: 1,
        rotate: 1065,
        duration: 2.8,
        ease: "Stong.in"
      });
    }
  };
</script>
