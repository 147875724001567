<template>
  <div class="quiz text-gray-100 page_content -mt-8 md:mt-0 fluid_content_container">
    <template v-if="cushion">
      <img v-if="$store.state.language === 'en'" class="w-2/3 mx-auto" src="../assets/img/header_wissen_10_em_green_en.png">
      <img v-else class="w-2/3 mx-auto" src="../assets/img/header_wissen_10_em_green_de.png">
    </template>
    <template v-else>
      <template v-if="green">
        <img v-if="$store.state.language === 'en'" class="w-2/3 mx-auto" src="../assets/img/header_wissen_10_green_en.png">
        <img v-else class="w-2/3 mx-auto" src="../assets/img/header_wissen_10_green_de.png">
      </template>
      <template v-else>
        <img v-if="$store.state.language === 'en'" class="w-2/3 mx-auto" src="../assets/img/header_wissen_10_en.png">
        <img v-else class="w-2/3 mx-auto" src="../assets/img/header_wissen_10_de.png">
      </template>
    </template>

    <div v-if="currentQuestion === -1" class="-mt-16 lg:-mt-24 text-center flex flex-col pb-10 lg:pb-20">
      <template v-if="book">
        <img v-if="$store.state.language === 'en'" class="w-full mt-8" src="../assets/img/historie_special_en.png">
        <img v-else class="w-full mt-8" src="../assets/img/historie_special_de.png">
      </template>
      <template v-else-if="cushion">
        <img v-if="$store.state.language === 'en'" class="w-full mt-8" src="../assets/img/cushion_01_en.png">
        <img v-else class="w-full mt-8" src="../assets/img/cushion_01_de.png">
      </template>
      <template v-else-if="earbuds">
        <img v-if="$store.state.language === 'en'" class="w-full mt-8" src="../assets/img/inear_en.png">
        <img v-else class="w-full mt-8" src="../assets/img/inear_de.png">
      </template>
      <template v-else>
        <img v-if="$store.state.language === 'en'" class="w-full" src="../assets/img/usb_01_en.png">
        <img v-else class="w-full" src="../assets/img/usb_01_de.png">
      </template>
      <h1 class="font-bold mt-2 md:mt-3" v-html="texts.heading"></h1>
      <div class="mt-4" v-html="texts.intro1"></div>
      <div v-if="!earbuds" class="mt-2 md:mt-4" v-html="introText"></div>
      <button class="btn btn_white mx-auto mt-4 mb-4 md:mb-8 font-bold" @click="startClick">
        {{ $store.getters.texts("quiz.startButton") }}
      </button>
      <div v-if="!earbuds" class="text-xs text-gray-200">{{ $store.getters.texts("quiz.smallPrint") }}</div>
    </div>

    <div v-if="currentQuestion >= 0" class="-mt-4 lg:-mt-10 pb-10 lg:pb-20">
      <transition-group class="relative" name="fade" tag="div">
        <div v-for="(task, index) in tasks" v-show="currentQuestion === index" :key="index">
          <div class="text-xl md:text-2xl font-bold text-center border-t border-b py-3" v-html="task.question"></div>
          <div class="flex flex-col gap-3 mt-6">
            <div
                v-for="(option, optionIndex) in task.options"
                :class="questionClass[optionIndex] + (answered ? ' answered' : '')"
                class="question_container w-full md:w-3/4 mx-auto flex flex-col bg-gray-300"
                @click="answerClick(optionIndex)"
            >
              <div class="question py-1 font-bold relative">
                <span class="absolute left-1 bg-gray-600 text-white w-6 h-6">{{ letter[optionIndex] }}</span>
                <span class="ml-8 mr-1" v-html="option.value"></span>
              </div>
              <div class="marker h-2 w-full">
                <div class="marker_inner"></div>
              </div>
            </div>
          </div>
          <div class="mt-6 text-center md:text-sm text-gray-300">
            <span class="text-white">{{ index + 1 }}</span>/{{ tasks.length }}
          </div>
        </div>

        <div v-show="currentQuestion === tasks.length" :key="tasks.length" class="mb-6 lg:mb-8 flex flex-col">
          <div v-show="correct === tasks.length">
            <div class="relative">
              <img v-if="earbuds" class="absolute w-1/4 lg:w-1/3 -bottom-12 right-0 lg:-right-10" src="../assets/img/earbuds_03.png">
              <img v-else-if="cushion" class="absolute w-1/4 lg:w-1/3 -bottom-12 right-0 lg:-right-10" src="../assets/img/cushion-1.png">
              <img v-else class="absolute w-1/4 lg:w-1/3 -bottom-12 right-0 lg:-right-10" src="../assets/img/usb_03.png">
            </div>
            <h2 class="text-center text-2xl">
              {{ $store.getters.texts("quiz.won.heading") }}
            </h2>
            <div class="text-center mt-3">
              <div v-if="!done" v-html="wonText"></div>

              <div class="w-5/6 mx-auto mt-12" v-html="html"></div>
              <div v-if="!done" class="relative">
                <img v-if="earbuds" class="absolute w-1/4 lg:w-2/5 bottom-8 lg:-bottom-8 left-0 lg:-left-24" src="../assets/img/earbuds_02.png">
                <img v-else-if="cushion" class="absolute w-1/4 lg:w-2/5 bottom-8 lg:-bottom-8 left-0 lg:-left-24" src="../assets/img/cushion-2.png">
                <img v-else class="absolute w-1/4 lg:w-2/5 bottom-12 lg:bottom-10 left-0 lg:-left-24" src="../assets/img/usb_02.png">
              </div>
              <template v-else>
                <template v-if="$store.state.language === 'en'">
                  <div class="text">
                    Thank You!<br>
                    Your USB stick will be sent to the specified address in the next few days.
                  </div>
                  <button class="btn btn_white mt-6" onclick="window.dispatchEvent(new CustomEvent('backClick'));">
                    to the DILO insights
                  </button>
                </template>
                <template v-else>
                  <div class="text">
                    Vielen Dank!<br>
                    Ihr USB-Stick wird in den nächsten Tagen an die angegebene Adresse verschickt.
                  </div>
                  <button class="btn btn_white mt-6" onclick="window.dispatchEvent(new CustomEvent('backClick'));">
                    zu den DILO moments
                  </button>
                </template>
              </template>
            </div>
          </div>
          <div v-show="correct !== tasks.length" class="flex flex-col">
            <div class="relative h-12">
              <img v-if="earbuds" class="absolute w-2/5 bottom-0 left-0" src="../assets/img/earbuds_02.png">
              <img v-else-if="!cushion" class="absolute w-2/5 bottom-0 left-0" src="../assets/img/usb_02.png">
              <img v-if="earbuds" class="absolute w-1/4 bottom-6 right-0" src="../assets/img/earbuds_03.png">
              <img v-else-if="!cushion" class="absolute w-1/4 bottom-6 right-0" src="../assets/img/usb_03.png">
            </div>
            <div class="font-bold text-center mx-auto w-1/2">
              {{ $store.getters.texts("quiz.fail.heading1") + correct + $store.getters.texts("quiz.fail.heading2") + tasks.length }}<br>
              {{ $store.getters.texts("quiz.fail.heading3") }}
            </div>
            <div class="text-center mx-auto w-1/2 mt-4">
              {{ failText }}
            </div>
            <button class="btn btn_white mx-auto mt-6" @click="restart">
              {{ $store.getters.texts("quiz.fail.button") }}
            </button>
          </div>
        </div>
      </transition-group>
    </div>

  </div>
</template>

<script>
import quizAzubi from "@/assets/quiz-azubi.json";
import quizAzubiEn from "@/assets/quiz-azubi-en.json";
import quizTechnology from "@/assets/quiz-technology.json";
import quizTechnologyEn from "@/assets/quiz-technology-en.json";
import quizSF6 from "@/assets/quiz-sf6.json";
import quizSF6En from "@/assets/quiz-sf6-en.json";
import quizAltGases from "@/assets/quiz-alt-gases.json";
import quizAltGasesEn from "@/assets/quiz-alt-gases-en.json";
import quizFootball from "@/assets/quiz-football.json";
import quizFootballEn from "@/assets/quiz-football-en.json";
import quizProducts from "@/assets/quiz-products.json";
import quizProductsEn from "@/assets/quiz-products-en.json";
import quiz70 from "@/assets/quiz-70.json";
import quiz70En from "@/assets/quiz-70-en.json";
import quizHistory from "@/assets/quiz-history.json";
import quizHistoryEn from "@/assets/quiz-history-en.json";
import { ajaxifyForm } from "@/assets/js/form";

export default {
  name: "Quiz",
  components: { ajaxifyForm },
  props: { html: String },
  data() {
    return {
      answered: false,
      currentQuestion: -1,
      correct: 0,
      done: false,
      letter: [ "A", "B", "C", "D" ],
      questionClass: [ "", "", "", "" ],
      quizes: {
        "quiz-alternative-gase": quizAltGases,
        "quiz-alternative-gases": quizAltGasesEn,
        "quiz-azubi": quizAzubi,
        "quiz-trainee": quizAzubiEn,
        "quiz-fussball-em": quizFootball,
        "quiz-football-em": quizFootballEn,
        "quiz-technologie": quizTechnology,
        "quiz-technology": quizTechnologyEn,
        "quiz-sf6": quizSF6,
        "quiz-sf6-en": quizSF6En,
        "quiz-produkte": quizProducts,
        "quiz-products": quizProductsEn,
        "quiz-jubilaeum": quiz70,
        "quiz-anniversary": quiz70En,
        "quiz-historie": quizHistory,
        "quiz-history": quizHistoryEn,
      },
      tasks: [],
      texts: {},
    };
  },
  computed: {
    book() {return [ "quiz-historie", "quiz-history" ].includes(this.$route.params.handle);},
    cushion() { return this.$route.params.handle.includes("ball-em"); },
    earbuds() { return [ "quiz-jubilaeum", "quiz-anniversary" ].includes(this.$route.params.handle); },
    failText() {
      let count = 1;
      if (this.cushion) count = 2;
      if (this.earbuds) count = 3;
      return this.$store.getters.texts("quiz.fail.text" + count);
    },
    formID() {
      let result = "get_usb_stick";
      if (this.cushion) result = "get_cushion";
      if (this.earbuds) result = "get_earbuds";
      return result;
    },
    green() { return [ "quiz-alternative-gase", "quiz-alternative-gases", "quiz-historie", "quiz-history" ].includes(this.$route.params.handle); },
    introText() {
      let count = 2;
      if (this.cushion) count = 3;
      if (this.book) count = 4;
      return this.$store.getters.texts("quiz.intro" + count);
    },
    wonText() {
      let count = 2;
      if (this.cushion) count = 3;
      if (this.earbuds) count = 4;
      return this.$store.getters.texts("quiz.won.text" + count);
    },
  },
  methods: {
    answerClick(optionNr) {
      if (!this.answered) {
        let continueTime = 1500;
        let that = this;
        this.answered = true;
        let currentOptions = this.tasks[this.currentQuestion].options;
        if (currentOptions[optionNr].correct) {
          this.questionClass[optionNr] = "correct";
          this.correct++;
        } else {
          this.questionClass[optionNr] = "false";
          continueTime += 500;
          setTimeout(function() {
            for (let i = 0; i < 4; i++) {
              if (currentOptions[i].correct) that.questionClass[i] = "correct";
            }
          }, 500);
        }

        setTimeout(function() {
          that.currentQuestion++;
          that.questionClass = [];
          that.answered = false;
        }, continueTime);
      }
    },
    restart() {
      this.answered = false;
      this.currentQuestion = 0;
      this.$nextTick(function() { this.correct = 0; });
      this.questionClass = [];
      this.done = false;
    },
    startClick() {
      if (this.currentQuestion === -1) this.currentQuestion = 0;
      setTimeout(() => {
        ajaxifyForm(this.formID);
        let quizName = document.getElementById("quiz");
        if (quizName) quizName.value = this.$route.params.handle;
      }, 1000);
    },
  },
  watch: {
    "$route.params.handle"(newValue) {
      if (this.quizes[newValue]) {
        this.tasks = this.quizes[newValue].tasks;
        this.texts = this.quizes[newValue].texts;
      }
    },
    html(newValue) {
      if (newValue && this.currentQuestion >= 0) this.$nextTick(function() {
        ajaxifyForm(this.cushion ? "get_cushion" : "get_usb_stick");
        let quizName = document.getElementById("quiz");
        if (quizName) quizName.value = this.$route.params.handle;
      });
    }
  },
  mounted() {
    this.tasks = this.quizes[this.$route.params.handle].tasks;
    this.texts = this.quizes[this.$route.params.handle].texts;
    let that = this;
    window.addEventListener("backClick", function() {
      that.$router.push({ name: "Stories", params: { lang: that.$store.state.language === "en" ? "en" : "" } });
    });
    window.addEventListener("formSent", function() {
      that.done = true;
    });
  },
};
</script>
